interface col {
  title: string,
  children: undefined | Array<col>,
  dataIndex: string & Array<string> | undefined
}

/**
 * IExportCSV структура необходимых данных для экспорта данных в CSV
 */
interface IExportCSV {
  name: String,
  columns: Array<col>,
  data: Array<Object>,
}

function getChild(col: col): [Array<col> | undefined, boolean] {
  if (typeof col['children'] !== 'undefined') return [col['children'], true];
  return [undefined, false];
}

function getTitles(cols: Array<col>): Array<string> {
  const colsArr: Array<any> = [...cols];
  const colsTitle: Array<any> = [];
  let level: number = colsArr.length;

  while (colsArr.length > 0) {
    let c = colsArr.shift();
    let [child, f] = getChild(c);
    colsTitle.push(c['title'])
    if (f) {
      if (child) {
        colsArr.push(child[0]);
        for (let i = 1; i < child?.length; i++) {
          colsArr.push(child[i]);
          colsTitle.push('');
        }
      }
    }
    level--;
    if (level == 0 && colsArr.length > 0) {
      level = colsArr.length;
      colsTitle.push('\r\n');
    }
  }

  return colsTitle;
}

function getCols(cols: Array<col>): Array<string> {
  const colsArr: Array<any> = [...cols];
  const colsD: Array<any> & Array<Array<any>> = [];

  while (colsArr.length > 0) {
    let c = colsArr.shift();
    let [child, f] = getChild(c);
    if (f) {
      if (child) {
        child.reverse();
        for (let cc of child) {
          colsArr.unshift(cc);
        }
      }
      continue;
    }
    colsD.push(c['dataIndex']);
  }

  return colsD;
}

/**
 *  Возвращает индекс объекта
 */
function getIndex(obj: Object, index: string | Array<string>): string {
  if (typeof index == 'string') {
    return JSON.stringify(obj[index as keyof typeof obj]);
  }

  let o: Object = obj;
  for (let i of index) {
    o = o[i as keyof typeof o];
  }

  return JSON.stringify(o);
}

function join(arr: Array<any>, separator: string = '', ignore: string = ''): string {
  let string: string = '';
  for (let item of arr) {
    string += item
    if (item !== ignore) {
      string += separator
    }
  }
  return string;
}

/**
 * ExportCSV реализует логику ексопрта данных в формат CSV
 */
function ExportCSV(csv: IExportCSV): [String, String] {
  const colsTitle = getTitles(csv.columns);
  const cols = getCols(csv.columns);

  const now = new Date();
  const csvName: string = csv.name + `_${now.getFullYear()}${now.getMonth()}${now.getDate()}_${now.getHours()}${now.getMinutes()}.csv`;

  let csvContent: String = join(colsTitle, ',', '\r\n') + "\r\n";
  let csvMeta = "data:text/csv;charset=utf8,";

  csv.data.forEach(function (obj) {
    for (let c of cols) {
      csvContent += getIndex(obj, c) + ',';
    }
    csvContent += "\r\n";
  })

  return [csvName, encodeURI(csvMeta + csvContent)];
}

export default ExportCSV