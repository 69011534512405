import getRouteText from '@src/helpers/getRouteText';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { message, Table } from 'antd';
import dayjs from 'dayjs';

import { LeftSquareOutlined, RightSquareOutlined } from '@ant-design/icons';
import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import api from '@services/api';
import { ROUTE_REPORT } from '@src/constants';

import "./styles.less";
import ExportCSV from "@common/exportCSV/component";

require('dayjs/locale/ru')

class ReportVolumeFirstLinePage extends Component {
  state = {
    userListVolumes: [],
    loading: false,
    period: 0,
    periodText: undefined,
  }

  componentDidMount() {
    this.getVolumeFirstLine();
  }

  getVolumeFirstLine = async () => {
    const { period } = this.state;
    this.setState({ loading: true });

    const { status, data } = await api.getReportVolumeFirstLine({ period });

    if (status === 200 && data.status === 0) {
      if (data?.volume?.length) {
        this.setState({ userListVolumes: data.volume });
      } else {
        this.setState({ userListVolumes: [] });
      }
      if (data?.date_begin?.length) {
        this.setState({ periodText: dayjs(data.date_begin).locale('ru').format('MMM YYYY') });
      } else {
        this.setState({ periodText: undefined });
      }
    } else {
      message.error(data.message);
    }

    this.setState({ loading: false });
  }

  onChangePeriod = (e, delta) => {
    e.preventDefault();
    this.setState((state) => ({
      period: state.period + delta,
    }), async () => await this.getVolumeFirstLine());
  }

  render() {
    const { t } = this.props;
    const { userListVolumes, loading, period, periodText } = this.state;

    const date = periodText || dayjs().locale('ru').subtract((-1) * period, 'month').format('MMM YYYY');

    const columns = [
      {
        title: 'Пользователь',
        children: [
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => (
              <div className="flex flex-col 2xl:flex-row gap-1 column-row-block">
                <div className="flex flex-row gap-2 2xl:hidden column-title-block" >
                  <div>ID: {id}, </div>
                  <div>{record.first_name}</div>
                  <div>{record.last_name}</div>
                </div>
                <div className="flex flex-row gap-2 2xl:hidden column-title-block" >
                  <div className="grow">Всего</div>
                  <div className="w-16 text-right">{record.pv}</div>
                  <div className="w-16 text-right">{record.nv}</div>
                  <div className="w-16 text-right">{record.av}</div>
                </div>
                <div className="flex flex-row gap-2 2xl:hidden column-title-block" >
                  <div className="grow">За месяц</div>
                  <div className="w-16 text-right">{record.spv}</div>
                  <div className="w-16 text-right">{record.snv}</div>
                  <div className="w-16 text-right">{record.sav}</div>
                </div>
                <div className="hidden 2xl:block column-content-block st-table-num column_id">{id}</div>
              </div>
            ),
          },
          {
            title: 'Имя',
            dataIndex: 'first_name',
            key: 'first_name',
            render: (first_name) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block 2xl:hidden column-title-block">Имя:</div>
                <div className="column-content-block st-table-imfo type column_type">{ first_name }</div>
              </div>
            ),
          },
          {
            title: 'Фамилия',
            dataIndex: 'last_name',
            key: 'last_name',
            render: (last_name) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block 2xl:hidden column-title-block">Фамилия:</div>
                <div className="column-content-block st-table-imfo type column_type">{ last_name }</div>
              </div>
            ),
          },
        ],
      },
      {
        title: 'Текущие баллы',
        children: [
          {
            title: 'Личные',
            key: 'pv',
            dataIndex: 'pv',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block 2xl:hidden column-title-block">Личные (всего):</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={ Number(sum) }
                    displayType={ 'text' }
                    decimalScale={ 1 }
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
          {
            title: 'Сетевые',
            key: 'nv',
            dataIndex: 'nv',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block 2xl:hidden column-title-block">Сетевые (всего):</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={ Number(sum) }
                    displayType={ 'text' }
                    decimalScale={ 1 }
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
          {
            title: 'Итого',
            key: 'av',
            dataIndex: 'av',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block 2xl:hidden column-title-block">Итого (всего):</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={ Number(sum) }
                    displayType={ 'text' }
                    decimalScale={ 1 }
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
        ],
      },
      {
        title: 'Дельта за месяц',
        children: [
          {
            title: 'Личные',
            key: 'spv',
            dataIndex: 'spv',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block 2xl:hidden column-title-block">Личные (дельта):</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={ Number(sum) }
                    displayType={ 'text' }
                    decimalScale={ 1 }
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
          {
            title: 'Сетевые',
            key: 'snv',
            dataIndex: 'snv',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block 2xl:hidden column-title-block">Сетевые (дельта):</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={ Number(sum) }
                    displayType={ 'text' }
                    decimalScale={ 1 }
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
          {
            title: 'Итого',
            key: 'sav',
            dataIndex: 'sav',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block 2xl:hidden column-title-block">Итого (дельта):</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={ Number(sum) }
                    displayType={ 'text' }
                    decimalScale={ 1 }
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
        ],
      },
    ];

    return (
      <main className="report-volume-first-line-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader
            pageName={t('LEFT_MENU_'.concat(getRouteText(window.location.pathname)))}
            parentPageName={t('LEFT_MENU_REPORT')}
            parentPageLink={ROUTE_REPORT}
            withPaySubscription={false}
          />
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-5 items-center justify-between">
              <form className="static__header flex flex-row gap-4">
                <div className="period">
                  <span className=""><strong>Период :</strong></span>
                </div>
                <button
                    onClick={(e) => this.onChangePeriod(e, -1)}
                    title='Переключиться на предыдущий месяц'
                >
                  <LeftSquareOutlined />
                </button>
                <input className="input-date" value={date} disabled />
                <button
                    onClick={(e) => this.onChangePeriod(e, 1)}
                    disabled={period >= 0}
                    title='Переключиться на следующий месяц'
                >
                  <RightSquareOutlined />
                </button>
              </form>
              <ExportCSV columns={columns} getData={() => {return userListVolumes}}>Экспорт CSV</ExportCSV>
            </div>
            <div className="static__contents !py-4">
              <div className="static__cont">
                <Table
                  dataSource={userListVolumes}
                  columns={columns}
                  loading={loading}
                  rowKey={(record) => record.id}
                  rowClassName="st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0"
                  pagination={false}
                  className='static-table'
                  bordered
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(
  ReportVolumeFirstLinePage
);
