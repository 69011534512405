import AppModal from '@common/appModal';
import AppDrawer from '@common/drawer/appDrawer';
import ModalCheckBalance from '@common/drawer/ModalCheckBalance';
import GoogleAuthForm from '@common/googleAuthForm';
import AmbassadorsClubRoute from '@routes/ambassadorsClub';
import TgChannelClubRoute from '@routes/ambassadorsClub/tgChannel';
import MyNFTRoute from '@routes/ambassadorsClub/myNtf';
import BonusProgramsRoute from '@routes/bonusPrograms';
import EducationRoute from '@routes/education';
import EducationAcademyRoute from '@routes/education/educationAcademy';
import EducationBaseRoute from '@routes/education/educationBase';
import PartnersProgramsRoute from '@routes/ambassadorsClub/programs';
import PromoRoute from '@routes/promo';
import PromoJuniorRoute from '@routes/promo/junior';
import PromoJunior1Route from '@routes/promo/junior/1';
import PromoJunior2Route from '@routes/promo/junior/2';
import PromoTravelRoute from '@routes/promo/travel';
import PromoTravel1Route from '@routes/promo/travel/1';
import PromoTravel2Route from '@routes/promo/travel/2';
import PromoWatchRoute from '@routes/promo/watch';
import ReportRoute from '@routes/report';
import ReportUserListRoute from '@routes/report/userList';
import ReportVolumeFirstLineRoute from '@routes/report/volumeFirstLine';
import ReportVolumeUserListRoute from '@routes/report/volumeUserList';
import UniversityRoute from '@routes/university';
import LeaderForMillionRoute from '@routes/university/leaderForMillion';
import MasterOfWordsRoute from '@routes/university/masterOfWords';
import BullranIndexRoute from 'src/components/routes/bullranIndex';
import StructureRoute from '@routes/ambassadorsClub/structure';
import ReferralsLinkRoute from '@routes/referralsLink';
import TransactionsRoute from '@routes/transactions';
import VerifyUpdateUserInfoRoute from '@routes/verifyUpdateUserInfo';
import VerifyWithdrawalRoute from '@routes/verifyWithdrawal';
import WalletRoute from '@routes/wallet';
import ReportLevelRaiseRoute from "@routes/report/levelRaise";
import ReportUsersSalesRoute from "@routes/report/usersSales";
import ReportNextFlowRoute from "@routes/report/nextFlow";
import { consultant, programs } from '@src/helpers/programs';
import { useAppSelector } from '@state/store';
import React, { FC } from 'react';
import ErrorRoute from '@routes/error';
import ProductsRoute from '@routes/products';
import RootRoute from '@routes/root';
import AnniversaryRoute from "@routes/anniversary";
import {
  ROUTE_404,
  // ROUTE_CONSULTANT,
  ROUTE_EDUCATION,
  ROUTE_EDUCATION_BASE,
  ROUTE_LOGIN,
  ROUTE_PRODUCTS,
  ROUTE_REFERRALS_LINKS,
  ROUTE_REGISTRATION,
  ROUTE_REGISTRATION_WITH_ID,
  ROUTE_AMBASSADORS_CLUB_STRUCTURE,
  ROUTE_ROOT,
  ROUTE_VERIFY_EMAIL,
  ROUTE_VERIFY_RESET_PASSWORD,
  ROUTE_VERIFY_WITHDRAWAL,
  ROUTE_WALLET,
  ROLE_ADMIN,
  ROUTE_TRANSACTIONS,
  ROUTE_BONUS_PROGRAMS,
  ROUTE_VERIFY_UPDATE_USER_INFO,
  ROUTE_BULLRAN_INDEX,
  ROUTE_UNIVERSITY_CONSULTANT,
  ROUTE_UNIVERSITY_LEADER,
  ROUTE_UNIVERSITY,
  ROUTE_UNIVERSITY_MASTER,
  ROUTE_PROMO,
  ROUTE_PROMO_WATCH,
  ROUTE_PROMO_JUNIOR_1,
  ROUTE_PROMO_JUNIOR_2,
  ROUTE_PROMO_JUNIOR,
  ROUTE_PROMO_TRAVEL_1,
  ROUTE_PROMO_TRAVEL_2,
  ROUTE_PROMO_TRAVEL,
  ROUTE_AMBASSADORS_CLUB,
  ROUTE_AMBASSADORS_CLUB_MY_NFT,
  ROUTE_AMBASSADORS_CLUB_PROGRAMS,
  ROUTE_REPORT_USERS_LIST,
  ROUTE_REPORT,
  ROUTE_REPORT_VOLUME_FIRST_LINE,
  ROUTE_REPORT_VOLUME_USER_LIST,
  ROUTE_TELEGRAM_CHANNELS_BOT,
  ROUTE_REPORT_USERS_SALES,
  ROUTE_REPORT_LEVEL_RAISE,
  ROUTE_ANNIVERSARY,
  ROUTE_REPORT_NEXT_FLOW,
} from '@src/constants';
import { Switch, Route, Redirect } from "react-router-dom";

import LeftMenu from '@layouts/main/leftMenu';
import TopBar from '@layouts/main/topBar';

const MainLayout: FC = () => {
  const userInfo = useAppSelector((state) => state.user.info);

  return (
    <div id="page-parent" className="page main-page">
      <AppDrawer />
      <AppModal />
      <ModalCheckBalance />
      <GoogleAuthForm />
      <LeftMenu showTelegramChat={false} />
      <TopBar showSearch={false} showDayNight={false} />
      <Switch>
        <Route path={ROUTE_VERIFY_EMAIL} component={RootRoute} />
        <Route path={ROUTE_VERIFY_WITHDRAWAL} component={VerifyWithdrawalRoute} />
        <Route path={ROUTE_VERIFY_UPDATE_USER_INFO} component={VerifyUpdateUserInfoRoute} />
        {/*<Route path={ROUTE_CONSULTANT} render={() => <EducationAcademyRoute program={consultant} />}  />*/}
        <Route path={ROUTE_EDUCATION_BASE} component={EducationBaseRoute} />
        {programs
          .filter((x) => x.programs_category === 2)
          .map((x) =>
            <Route key={x.linkPath} path={x.linkPath} render={() => <EducationAcademyRoute program={x} />}  />
          )
        }
        <Route exact={true} path={ROUTE_EDUCATION} component={EducationRoute} />
        <Route path={ROUTE_BONUS_PROGRAMS} component={BonusProgramsRoute} />
        <Route path={ROUTE_REFERRALS_LINKS} component={ReferralsLinkRoute} />
        <Route path={ROUTE_BULLRAN_INDEX} component={BullranIndexRoute} />
        <Route path={ROUTE_PRODUCTS} component={ProductsRoute} />
        <Route path={ROUTE_TELEGRAM_CHANNELS_BOT} component={TgChannelClubRoute} />
        <Route path={ROUTE_AMBASSADORS_CLUB_MY_NFT} component={MyNFTRoute} />
        <Route path={ROUTE_AMBASSADORS_CLUB_PROGRAMS} component={PartnersProgramsRoute} />
        <Route path={ROUTE_AMBASSADORS_CLUB_STRUCTURE} component={StructureRoute} />
        <Route path={ROUTE_AMBASSADORS_CLUB} component={AmbassadorsClubRoute} />
        <Route path={ROUTE_REPORT_USERS_LIST} component={ReportUserListRoute} />
        <Route path={ROUTE_REPORT_VOLUME_FIRST_LINE} component={ReportVolumeFirstLineRoute} />
        <Route path={ROUTE_REPORT_VOLUME_USER_LIST} component={ReportVolumeUserListRoute} />
        <Route path={ROUTE_REPORT_NEXT_FLOW} component={ReportNextFlowRoute} />
        <Route path={ROUTE_REPORT_USERS_SALES} component={ReportUsersSalesRoute} />
        <Route path={ROUTE_REPORT_LEVEL_RAISE} component={ReportLevelRaiseRoute} />
        <Route path={ROUTE_REPORT} component={ReportRoute} />
        <Route path={ROUTE_WALLET} component={WalletRoute} />
        <Route path={ROUTE_TRANSACTIONS} component={TransactionsRoute} />
        <Route path={ROUTE_PROMO_TRAVEL_1} component={PromoTravel1Route} />
        <Route path={ROUTE_PROMO_TRAVEL_2} component={PromoTravel2Route} />
        <Route path={ROUTE_PROMO_TRAVEL} component={PromoTravelRoute} />
        <Route path={ROUTE_PROMO_JUNIOR_1} component={PromoJunior1Route} />
        <Route path={ROUTE_PROMO_JUNIOR_2} component={PromoJunior2Route} />
        <Route path={ROUTE_PROMO_JUNIOR} component={PromoJuniorRoute} />
        <Route path={ROUTE_PROMO_WATCH} component={PromoWatchRoute} />
        <Route path={ROUTE_PROMO} component={PromoRoute} />
        <Route path={ROUTE_UNIVERSITY_CONSULTANT} render={() => <EducationAcademyRoute program={consultant} />}  />
        <Route path={ROUTE_UNIVERSITY_MASTER} component={MasterOfWordsRoute} />
        <Route path={ROUTE_UNIVERSITY_LEADER} component={LeaderForMillionRoute} />
        <Route path={ROUTE_UNIVERSITY} component={UniversityRoute} />
        <Route path={ROUTE_ANNIVERSARY} component={AnniversaryRoute} />
        {userInfo?.role_id === ROLE_ADMIN && (
          <>
            {/*<Route path={ROUTE_PROGRAMS_HASH_NEW} component={ProgramsHashNewRoute} />*/}
            {/*<Route path={ROUTE_TEST_PLAYER} component={TestPlayerRoute} />*/}
          </>
        )}
        <Route path={ROUTE_404} component={ErrorRoute} />
        <Route exact={true} path={ROUTE_ROOT} component={RootRoute} />
        <Redirect from={ROUTE_REGISTRATION_WITH_ID} to={ROUTE_ROOT} />
        <Redirect from={ROUTE_REGISTRATION} to={ROUTE_ROOT} />
        <Redirect from={ROUTE_LOGIN} to={ROUTE_ROOT} />
        <Redirect from={ROUTE_VERIFY_RESET_PASSWORD} to={ROUTE_ROOT} />
        <Redirect to={ROUTE_404} />
      </Switch>
    </div>
  );
}

export default MainLayout;
