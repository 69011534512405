import {withTranslation} from "react-i18next";
import React, {Component} from "react";
import MobileHeader from "@common/mobileHeader";
import CatalogHeader from "@common/catalogHeader";
import {PAGE_CHANGER_STEPS, ROUTE_REPORT} from "@src/constants";
import {DatePicker, Space, Table} from "antd";
import Search from "antd/es/input/Search";
import ExportCSV from "@common/exportCSV/component";
import api from '@services/api';
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

class LevelRaisePage extends Component {

    state = {
        levelList: [],
        loading: false,
        count: 0,
        page: 1,
        pageSize: PAGE_CHANGER_STEPS[0],
        filter: {
            dateStart: '',
            dateEnd: '',
            level: '',
        }
    }

    componentDidMount() {
        this.getLevelList()
    }

    getLevelList = async () => {
        const {page, pageSize, filter} = this.state;
        this.setState({loading: true});

        const { status, data } = await api.getReportLevelRaise({
            page: page,
            pageSize,
            date_start: filter.dateStart === "" ? undefined : filter.dateStart,
            date_end: filter.dateEnd === "" ? undefined : filter.dateEnd,
            level: filter.level == "" ? undefined : filter.level
        });

        if (status === 200 && data?.data) {
            this.setState({
                levelList: data.data ?? [],
                count: data.totalCount ?? 0,
            })
        }

        this.setState({loading: false});
    }

    changePage = (newPage, newPageSize) => {
        const {pageSize} = this.state;

        this.setState({
            pageSize: newPageSize,
            page: Number(pageSize) !== newPageSize ? 1 : newPage,
        }, async () => await this.getLevelList())
    }

    onSearch = (name, value) => {
        this.setState((state) => ({
            filter: {
                ...state.filter,
                [name]: value,
            },
            page: 1,
        }), async () => await this.getLevelList());
    }

    onChange = (dates) => {
        let dateStart = dates[0] === '' ? '' : dates[0],
        dateEnd = dates[1] === '' ? '' : dates[1];

        this.setState({
            filter: {
                ...this.state.filter,
                dateStart: dateStart,
                dateEnd: dateEnd
            }
        }, async () => await this.getLevelList())

        this.getLevelList()
    }

    render() {
        const { t } = this.props;

        let { levelList, loading, page , count, pageSize } = this.state;

        const paginationChanger = {
            onChange: this.changePage,
            current: page,
            total: count,
            pageSize: pageSize,
            size: 'small',
            showSizeChanger: true,
            pageSizeOptions: PAGE_CHANGER_STEPS,
        }

        const { RangePicker } = DatePicker;

        const columns = [
            {
                title: 'Дата',
                dataIndex: 'date_create',
                key: 'date_create',
                render: (date) => (
                    <div className="flex flex-row gap-1 column-row-block">
                        <div className="block lg:hidden column-title-block">Дата:</div>
                        <div className="column-content-block st-table-imfo type column_type">{date}</div>
                    </div>
                ),
            },
            {
                title: 'ФИО',
                dataIndex: 'id',
                key: 'id',
                render: (id, record) => (
                    <div className="flex flex-row gap-1 column-row-block">
                        <div className="block lg:hidden column-title-block">ФИО:</div>
                        <div className="column-content-block st-table-imfo type column_type">{record.first_name} {record.last_name}</div>
                    </div>
                ),
            },
            {
                title: 'Предыдущий уровень',
                dataIndex: 'level_start',
                key: 'level_start',
                render: (level_start) => (
                    <div className="flex flex-row gap-1 column-row-block">
                        <div className="block lg:hidden column-title-block">Предыдущий уровень:</div>
                        <div className="column-content-block st-table-imfo type column_type">{level_start}</div>
                    </div>
                ),
            },
            {
                title: 'Текущий уровень',
                dataIndex: 'level_end',
                key: 'level_end',
                render: (level_end) => (
                    <div className="flex flex-row gap-1 column-row-block">
                        <div className="block lg:hidden column-title-block">Текущий уровень:</div>
                        <div className="column-content-block st-table-imfo type column_type">{level_end}</div>
                    </div>
                ),
            }
        ]

        return (
            <main className="report-user-list-page">
                <div className="wrap">
                    <MobileHeader/>
                    <CatalogHeader
                        pageName={t('LEFT_MENU_REPORT/LEVEL-RAISE')}
                        parentPageName={t('LEFT_MENU_REPORT')}
                        parentPageLink={ROUTE_REPORT}
                        withPaySubscription={false}
                    />
                    <div className="flex flex-col gap-1">
                        <form className="static__header static__headerm flex flex-col gap-4">
                            <div className="static__head-block">
                                <div className="flex flex-row gap-1">
                                    <div className="ant-checkbox-wrapper pr-2">
                                        <span className=""><strong>Пакет :</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="static__head-block">
                                <div className="flex flex-col gap-1">
                                    <div className="flex flex-row gap-1 flex-wrap">
                                        <div className="">
                                            <Search addonBefore="Уровень:" onSearch={(value) => this.onSearch('level', value)}
                                                    allowClear/>
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-1 flex-wrap">
                                        <div className="">
                                            <Space>
                                                <RangePicker value={[
                                                        this.state.filter.dateStart.toString() === "" ? "" : dayjs(this.state.filter.dateStart),
                                                        this.state.filter.dateEnd.toString() === "" ? "" : dayjs(this.state.filter.dateEnd),
                                                    ]}
                                                             onCalendarChange={(moment, dates) => {this.onChange(dates)}} />
                                            </Space>
                                        </div>
                                        <ExportCSV
                                          columns={columns}
                                          getData={()=>{return levelList}}
                                        >
                                            Экспорт CSV</ExportCSV>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="static__contents !py-4">
                            <div className="static__cont">
                                <Table
                                    dataSource={levelList}
                                    columns={columns}
                                    loading={loading}
                                    pagination={paginationChanger}
                                    className='static-table'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default withTranslation()(
    LevelRaisePage
);