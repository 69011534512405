import changeAuthByUser from '@src/helpers/changeAuthByUser';
import store from '@state/store';
import classNames from 'classnames';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {Button, Checkbox, List, message, Modal, Popover, Table} from 'antd';
import Search from 'antd/es/input/Search';
import dayjs from 'dayjs';

import {MenuOutlined} from '@ant-design/icons';
import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import {DEFAULT_ROLES, ROLE_FILTER} from '@routes/report/userList/constants';
import api from '@services/api';
import {PAGE_CHANGER_STEPS, ROUTE_REPORT} from '@src/constants';

import "./styles.less";
import ExportCSV from "@common/exportCSV/component";

class UserListPage extends Component {
  state = {
    userList: [],
    loading: false,
    roles: DEFAULT_ROLES,
    rolesFilter: ROLE_FILTER,
    // date_from: dayjs(`PERIOD_DELTA_PERIOD_1_WEEK`).format(DATE_FORMAT),
    // date_to: dayjs().format(DATE_FORMAT),
    count: 0,
    page: 1,
    pageSize: PAGE_CHANGER_STEPS[0],
    openedInfo: undefined,
    checkCount: DEFAULT_ROLES.length,
    search: {
      id: undefined,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      phone: undefined,
      telegram: undefined,
    },
    isDetailModalOpen: false,
    detailModalData: {
      curator: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        telegram: ''
      },
      dateRegister: "",
      dateInvite: ""
    },
    isHigherUpsModalOpen: false,
    isHigherUpsModalLoading: false,
    userCuratorListModalData: []
  }

  componentDidMount() {
    this.getUsersList();
  }

  getUsersList = async (forCSV = false) => {
    const {page, pageSize, roles, search} = this.state;
    this.setState({loading: true});

    let {status, data} = await api.getReportUsersList({
      page: forCSV ? 0 : page - 1,
      pageSize: forCSV ? 0 : pageSize,
      roles,
      id: search.id?.toString() ?? undefined,
      first_name: search.first_name?.toString() ?? undefined,
      last_name: search.last_name?.toString() ?? undefined,
      email: search.email?.toString() ?? undefined,
      phone: search.phone?.toString() ?? undefined,
      telegram: search.telegram?.toString() ?? undefined,
    });

    if (status === 200 && data?.data) {
      if (forCSV) {
        this.setState({loading: false});
        return data.data ?? [];
      }
      this.setState({
        userList: data.data ?? [],
        count: data.totalCount ?? 0,
      });
    }

    this.setState({loading: false});
  }

  changePage = (newPage, newPageSize) => {
    const {pageSize} = this.state;

    this.setState({
      pageSize: newPageSize,
      page: Number(pageSize) !== newPageSize ? 1 : newPage,
    }, async () => await this.getUsersList())
  }

  setOpenedInfo = (user_id) => {
    const {openedInfo} = this.state;

    if (openedInfo === user_id) {
      this.setState({openedInfo: undefined})
    } else {
      this.setState({openedInfo: user_id})
    }
  }

  onChangeRoles = (checkedValues) => {
    const {timer} = this.state;
    timer && clearTimeout(timer);
    const newTimer = setTimeout(async () => await this.getUsersList(), 1000);
    this.setState({
      roles: checkedValues,
      page: 1,
      timer: newTimer,
      checkCount: checkedValues.length,
    });
  }

  onCheckAllRoles = (value) => {
    const {timer} = this.state;
    const checkAll = value?.target.checked;
    timer && clearTimeout(timer);
    const newTimer = setTimeout(async () => await this.getUsersList(), 1000);
    const roles = checkAll ? ROLE_FILTER.map((x) => x.value) : [];
    this.setState({
      roles,
      page: 1,
      timer: newTimer,
      checkCount: roles.length,
    });
  }

  onSearch = (name, value) => {
    console.log({
      _f: 'UserListPage-onChange-75',
      name,
      value,
    });
    const roles = ROLE_FILTER.map((x) => x.value);
    this.setState((state) => ({
      search: {
        ...state.search,
        [name]: value,
      },
      page: 1,
      roles,
      checkCount: roles.length,
    }), async () => await this.getUsersList());
  }

  authByUser = async (e, userId) => {
    const {userFullInfo, history} = this.props;

    e.preventDefault();

    await changeAuthByUser(store.dispatch, userFullInfo, userId, history);
  }

  registerToNextFlow = async (userId) => {
    const {status, data} = await api.updateWantToNextFlow({user_id: userId});

    if (status === 200 && data?.status === 0) {
      message.success(data?.message);
      this.getUsersList();
    } else {
      message.warning(data?.message);
    }
  }

  registerToRepeatNextFlow = async (userId) => {
    const {status, data} = await api.updateWantRepeatToNextFlow({user_id: userId});

    if (status === 200 && data?.status === 0) {
      message.success(data?.message);
      this.getUsersList();
    } else {
      message.warning(data?.message);
    }
  }

  render() {
    const {t, userInfo} = this.props;
    const {
      userList,
      loading,
      count,
      page,
      pageSize,
      rolesFilter,
      roles,
      checkCount,
      isDetailModalOpen,
      detailModalData,
      isHigherUpsModalOpen,
      isHigherUpsModalLoading,
      userCuratorListModalData,
    } = this.state;

    const showDetailModal = (curator = {}, dateCreate = '', dateInvite = '') => {
      this.setState({
        isDetailModalOpen: true,
        detailModalData: {
          curator: curator,
          dateCreate: dateCreate,
          dateInvite: dateInvite
        }
      })
    }

    const closeDetailModal = () => {
      this.setState({
        isDetailModalOpen: false,
        detailModalData: {
          curator: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            telegram: ''
          },
          dateRegister: "",
          dateInvite: ""
        }
      })
    }

    const showUserCuratorListModal = async (userId) => {
      this.setState({
        isHigherUpsModalOpen: true,
        isHigherUpsModalLoading: true,
      })

      const {status, data} = await api.getReportUserCuratorList({user_id: userId})

      if (status === 200 && data?.data) {
        this.setState({
          userCuratorListModalData: data?.data ?? []
        })
      }

      this.setState({
        isHigherUpsModalLoading: false
      })
    }

    const closeHigherUpsModal = () => {
      this.setState({
        isHigherUpsModalOpen: false,
        isHigherUpsModalLoading: false,
        userCuratorListModalData: []
      })
    }

    const higherupsColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">ID:</div>
            <div
              className="column-content-block st-table-num column_id">{id}</div>
          </div>
        ),
      },
      {
        title: 'Имя',
        dataIndex: 'first_name',
        key: 'first_name',
        render: (first_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Имя:</div>
            <div
              className="column-content-block st-table-imfo type column_type">{first_name}</div>
          </div>
        ),
      },
      {
        title: 'Фамилия',
        dataIndex: 'last_name',
        key: 'last_name',
        render: (last_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Фамилия:</div>
            <div
              className="column-content-block st-table-imfo type column_type">{last_name}</div>
          </div>
        ),
      },
      {
        title: 'Почта',
        dataIndex: 'email',
        key: 'email',
        render: (email) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Почта:</div>
            <div
              className="column-content-block st-table-imfo type column_type">{email}</div>
          </div>
        ),
      },
      {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone',
        render: (phone) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Телефон:</div>
            <div
              className="column-content-block st-table-imfo type column_type">{phone}</div>
          </div>
        ),
      },
      {
        title: 'Телеграмм',
        dataIndex: 'telegram',
        key: 'telegram',
        render: (telegram) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Телеграмм:</div>
            <div
              className="column-content-block st-table-imfo type column_type">{telegram}</div>
          </div>
        ),
      },
      {
        title: 'Пакет',
        dataIndex: 'role_name',
        key: 'role_name',
        render: (role_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Пакет:</div>
            <div
              className="column-content-block st-table-imfo type column_type">{role_name}</div>
          </div>
        ),
      },
    ];



    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (id, record) => (
          <div className={classNames(
            "flex flex-row gap-1 column-row-block",
            (record.isWantToNextFlow !== null && record.isWantToNextFlow !== 0 ? 'bg-yellow-300' : ''),
            (record.isWantToRepeatNextFlow !== null && record.isWantToRepeatNextFlow !== 0 ? 'bg-blue-300' : ''),
          )}>
            <div className="block lg:hidden column-title-block">ID:</div>
            <div className="column-content-block st-table-num column_id">
              <div title={[
                record.isWantToNextFlow && record.isWantToNextFlow !== 0 ? 'Записано желание о переносе обучения на следующий поток' : '',
                record.isWantToRepeatNextFlow !== null && record.isWantToRepeatNextFlow !== 0 ? 'Записано желание о повторном обучения в следующем потоке' : ''
              ].join("\n")}>
                {id}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'Имя',
        dataIndex: 'first_name',
        key: 'first_name',
        render: (first_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Имя:</div>
            <div className="column-content-block st-table-imfo type column_type">{first_name}</div>
          </div>
        ),
      },
      {
        title: 'Фамилия',
        dataIndex: 'last_name',
        key: 'last_name',
        render: (last_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Фамилия:</div>
            <div className="column-content-block st-table-imfo type column_type">{last_name}</div>
          </div>
        ),
      },
      {
        title: 'Почта',
        dataIndex: 'email',
        key: 'email',
        render: (email) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Почта:</div>
            <div className="column-content-block st-table-imfo type column_type">{email}</div>
          </div>
        ),
      },
      {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone',
        render: (phone) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Телефон:</div>
            <div className="column-content-block st-table-imfo type column_type">{phone}</div>
          </div>
        ),
      },
      {
        title: 'Уровень',
        dataIndex: 'level',
        key: 'level',
        render: (level) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Уровень:</div>
            <div className="column-content-block st-table-imfo type column_type">{level}</div>
          </div>
        ),
      },
      {
        title: 'Телеграмм',
        dataIndex: 'telegram',
        key: 'telegram',
        render: (telegram) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Телеграмм:</div>
            <div className="column-content-block st-table-imfo type column_type">{telegram}</div>
          </div>
        ),
      },
      // {
      //   title: 'Дата регистрации',
      //   dataIndex: 'date_create',
      //   key: 'date_create',
      //   render: (date) => (
      //     <div className="flex flex-row gap-1 column-row-block">
      //       <div className="block lg:hidden column-title-block">Дата регистрации:</div>
      //       <div className="column-content-block st-table-imfo date column_date">{dayjs(date).format('DD.MM.YY')}</div>
      //     </div>
      //   ),
      // },
      // {
      //   title: 'ID Куратора',
      //   dataIndex: 'curator_id',
      //   key: 'curator_id',
      //   render: (curator_id) => (
      //     <div className="flex flex-row gap-1 column-row-block">
      //       <div className="block lg:hidden column-title-block">ID Куратора</div>
      //       <div className="column-content-block st-table-imfo date column_date">{curator_id}</div>
      //     </div>
      //   ),
      // },
      {
        title: 'Дата покупки',
        dataIndex: ['currentBoughtProduct', 'date_update'],
        key: 'currentBoughtProduct.date_update',
        render: (date) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Дата покупки:</div>
            <div
              className="column-content-block st-table-imfo date column_date">{date ? dayjs(date).format('DD.MM.YY') : '-'}</div>
          </div>
        ),
      },
      {
        title: 'Пакет',
        key: 'currentProduct.name',
        dataIndex: ['currentProduct', 'name'],
        render: (name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Пакет:</div>
            <div className="column-content-block st-table-imfo date column_date">{name}</div>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: `id_moreInfo`,
        render: (id, record) => {
          // const content = getTransactionDetailTable(record);

          return (
            <>
              <Popover content={() => {
                return (
                  <List>
                    <List.Item>
                      {
                        userInfo.role_id === 999 && (
                          <button onClick={(e) => this.authByUser(e, record.id)}>
                            Login
                          </button>
                        )
                      }
                    </List.Item>
                    {/*
                      content ? (
                        <List.Item>
                          {
                            <Popover
                              title="Детальная информация по транзакции"
                              placement='bottomRight'
                              // open={openedInfo === record.id}
                              content={content}
                            >
                              <div
                                className="st__form-moreInfo"
                                onClick={() => this.setOpenedInfo(record.id)}
                              >
                                <QuestionCircleOutlined/>
                              </div>
                            </Popover>
                          }
                        </List.Item>
                      ) : ""
                    */}
                    <List.Item>
                      <button onClick={() => {
                        showDetailModal({
                          firstName: record?.curator?.first_name ?? '',
                          lastName: record?.curator?.last_name ?? '',
                          email: record?.curator?.email ?? '',
                          phone: record?.curator?.phone ?? '',
                          telegram: record?.curator?.telegram ?? ''
                        }, record?.date_create ?? '', record?.invite_to_base ?? '')
                      }}>
                        Детальная информация
                      </button>
                    </List.Item>
                    <List.Item>
                      <button onClick={() => {this.registerToNextFlow(id)}}>
                        Запись на перенос обучения
                      </button>
                    </List.Item>
                    <List.Item>
                      <button onClick={() => {this.registerToRepeatNextFlow(id)}}>
                        Запись на повтор обучения
                      </button>
                    </List.Item>
                    <List.Item>
                      {
                        <button onClick={() => showUserCuratorListModal(id)}>
                          Посмотреть всех вышестоящих
                        </button>
                      }
                    </List.Item>
                  </List>
                )
              }} placement='rightBottom' trigger='click'>
                <Button shape='circle' icon={<MenuOutlined/>}/>
              </Popover>
            </>
          );
        },
        fixed: 'right'
      },
    ];

    const paginationChanger = {
      onChange: this.changePage,
      current: page,
      total: count,
      pageSize: pageSize,
      size: 'small',
      showSizeChanger: true,
      pageSizeOptions: PAGE_CHANGER_STEPS,
    }

    return (
      <main className="report-user-list-page">
        <div className="wrap">
          <MobileHeader/>
          <CatalogHeader
            pageName={t('LEFT_MENU_REPORT/USERS')}
            parentPageName={t('LEFT_MENU_REPORT')}
            parentPageLink={ROUTE_REPORT}
            withPaySubscription={false}
          />
          <div className="flex flex-col gap-1">
            <form className="static__header static__headerm flex flex-col gap-4">
              <div className="static__head-block">
                <div className="flex flex-row gap-1">
                  <div className="ant-checkbox-wrapper pr-2">
                    <span className=""><strong>Пакет :</strong></span>
                  </div>
                  <Checkbox
                    indeterminate={checkCount > 0 && checkCount < ROLE_FILTER.length}
                    checkAll={checkCount === ROLE_FILTER.length}
                    onChange={this.onCheckAllRoles}
                    checked={checkCount === ROLE_FILTER.length}
                  >
                    Все
                  </Checkbox>
                  <Checkbox.Group
                    options={rolesFilter}
                    value={roles}
                    onChange={this.onChangeRoles}
                  />
                </div>
              </div>
              <div className="static__head-block">
                <div className="flex flex-col gap-1">
                  <div className="flex flex-row gap-1 flex-wrap">
                    <div className="">
                      <Search addonBefore="ID:" onSearch={(value) => this.onSearch('id', value)}
                              allowClear/>
                    </div>
                    <div className="">
                      <Search addonBefore="Имя:"
                              onSearch={(value) => this.onSearch('first_name', value)}
                              allowClear/>
                    </div>
                    <div className="">
                      <Search addonBefore="Фамилия:"
                              onSearch={(value) => this.onSearch('last_name', value)}
                              allowClear/>
                    </div>
                  </div>
                  <div className="flex flex-row gap-1 flex-wrap">
                    <div className="">
                      <Search addonBefore="Почта:"
                              onSearch={(value) => this.onSearch('email', value)} allowClear/>
                    </div>
                    <div className="">
                      <Search addonBefore="Телефон:"
                              onSearch={(value) => this.onSearch('phone', value)}
                              allowClear/>
                    </div>
                    <div className="">
                      <Search addonBefore="Телеграм:"
                              onSearch={(value) => this.onSearch('telegram', value)}
                              allowClear/>
                    </div>
                    <ExportCSV
                      columns={columns}
                      getData={() => this.getUsersList(true)}>
                      Экспорт CSV
                    </ExportCSV>
                  </div>
                </div>
              </div>
            </form>
            <Modal open={isDetailModalOpen} footer={false} onCancel={() => {
              closeDetailModal()
            }}>
              <List style={{paddingTop: .75 + 'rem'}}>
                <List header='Куратор' bordered>
                  <List.Item>ФИО: {detailModalData.curator.firstName} {detailModalData.curator.lastName}</List.Item>
                  <List.Item>Почта: {detailModalData.curator.email}</List.Item>
                  <List.Item>Телефон: {detailModalData.curator.phone}</List.Item>
                  <List.Item>Телеграм: {detailModalData.curator.telegram}</List.Item>
                </List>

                <List.Item>
                  Дата регистрации: {detailModalData.dateCreate}
                </List.Item>
                <List.Item>
                  Дата приглашения: {detailModalData.dateInvite}
                </List.Item>
              </List>
            </Modal>
            <Modal open={isHigherUpsModalOpen} footer={false} onCancel={closeHigherUpsModal}
                   width='max-content'>
              <Table className="m-2"
                     dataSource={userCuratorListModalData}
                     loading={isHigherUpsModalLoading}
                     pagination={false}
                     columns={higherupsColumns}
              />
            </Modal>
            <div className="static__contents !py-4">
              <div className="static__cont">
                <Table
                  dataSource={userList}
                  columns={columns}
                  loading={loading}
                  rowKey={(record) => record.id}
                  rowClassName="st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0"
                  pagination={paginationChanger}
                  className='static-table'
                  scroll={{x: 'max-content'}}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}


const mapStateToProps = (state) => ({
  userInfo: state.user.info,
  userFullInfo: state.user,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      UserListPage
    )
  )
);
