// import { openInNewTab } from '@components/landing/utils';
import { ApartmentOutlined, CheckSquareOutlined } from '@ant-design/icons'
import { openInNewTab } from '@components/landing/utils';
// import api from '@services/api';
// import { FORM_LOGIN, FORM_REGISTRATION, TELEGRAM_FOR_MAIN } from '@src/constants';
import { FORM_LOGIN, FORM_REGISTRATION, SOULMATE_ECOSYSTEM } from '@src/constants';
// import { Spin } from 'antd';
import React, { Component } from 'react';
import { Fancybox } from '@fancyapps/ui';
// import classNames from 'classnames';
// import OwlCarousel from 'react-owl-carousel2';

// import { ReactComponent as TelegramIcon } from '@components/landing/icons/telegram.svg';

// // import { ReactComponent as PlayIcon } from '@components/landing/icons/play.svg';
// import { ReactComponent as D1ImgIcon } from '@components/landing/icons/d1-img.svg';
// import { ReactComponent as D2P1Icon } from '@components/landing/icons/d2-1.svg';
// import { ReactComponent as D2P2Icon } from '@components/landing/icons/d2-2.svg';
// import { ReactComponent as D3P1Icon } from '@components/landing/icons/d3-1.svg';
// import { ReactComponent as TelegramIcon } from '@components/landing/icons/telegram.svg';
// import { ReactComponent as D3P2Icon } from '@components/landing/icons/d3-2.svg';
// // import { ReactComponent as SearchIcon } from '@components/landing/icons/search.svg';
// import { ReactComponent as D5P1Icon } from '@components/landing/icons/d5-1.svg';
// import { ReactComponent as D5P2Icon } from '@components/landing/icons/d5-2.svg';
// import { ReactComponent as D5P3Icon } from '@components/landing/icons/d5-3.svg';
// import { ReactComponent as D6P1Icon } from '@components/landing/icons/d6-1.svg';
// import { ReactComponent as D6P2Icon } from '@components/landing/icons/d6-2.svg';
// import { ReactComponent as D6P4Icon } from '@components/landing/icons/d6-4.svg';
// // import { ReactComponent as D6LabelIcon } from '@components/landing/icons/d6-label.svg';
// import { ReactComponent as D7LabelIcon } from '@components/landing/icons/d7-label.svg';
// import { ReactComponent as D7P1Icon } from '@components/landing/icons/d7-1.svg';
// import { ReactComponent as D7P3Icon } from '@components/landing/icons/d7-3.svg';
// import { ReactComponent as D7P2Icon } from '@components/landing/icons/d7-2.svg';
// import { ReactComponent as D7P4Icon } from '@components/landing/icons/d7-4.svg';
// import { ReactComponent as D7AlertIcon } from '@components/landing/icons/d7-alert.svg';
// import { ReactComponent as D7MAlertIcon } from '@components/landing/icons/d7m-alert.svg';
// import { ReactComponent as D7BrainIcon } from '@components/landing/icons/d7-brain.svg';
// // import { ReactComponent as D8CheckIcon } from '@components/landing/icons/d8-check.svg';
// // import { ReactComponent as D8P1Icon } from '@components/landing/icons/d8-1.svg';
// // import { ReactComponent as D8P2Icon } from '@components/landing/icons/d8-2.svg';
// // import { ReactComponent as D8P3Icon } from '@components/landing/icons/d8-3.svg';
// import { ReactComponent as D9P1Icon } from '@components/landing/icons/d9-1.svg';
// import { ReactComponent as D9P2Icon } from '@components/landing/icons/d9-2.svg';
// import { ReactComponent as D9P3Icon } from '@components/landing/icons/d9-3.svg';
// import { ReactComponent as D9P4Icon } from '@components/landing/icons/d9-4.svg';
// import { ReactComponent as D11Icon } from '@components/landing/icons/d11.svg';
// import { ReactComponent as D11TBgIcon } from '@components/landing/icons/d11t-bg.svg';
// import { ReactComponent as D11LineIcon } from '@components/landing/icons/d11-line.svg';
// import { ReactComponent as D11LabelIcon } from '@components/landing/icons/d11-label.svg';
// import { ReactComponent as D11P1Icon } from '@components/landing/icons/d11-1.svg';
// import { ReactComponent as D11P2Icon } from '@components/landing/icons/d11-2.svg';
// import { ReactComponent as D12P1Icon } from '@components/landing/icons/d12-1.svg';
// import { ReactComponent as D12P2Icon } from '@components/landing/icons/d12-2.svg';
// import { ReactComponent as D12P3Icon } from '@components/landing/icons/d12-3.svg';
// import { ReactComponent as D12IconIcon } from '@components/landing/icons/d12-icon.svg';
// import { ReactComponent as D12ImgIcon } from '@components/landing/icons/d12-img.svg';
// import { ReactComponent as D13ImgIcon } from '@components/landing/icons/d13-img.svg';
// import { ReactComponent as D13LineIcon } from '@components/landing/icons/d13-line.svg';
// import { ReactComponent as D14LabelIcon } from '@components/landing/icons/d14-label.svg';
// import { ReactComponent as D15BgIcon } from '@components/landing/icons/d15-bg.svg';
// import { ReactComponent as D15LabelIcon } from '@components/landing/icons/d15-label.svg';
// import { ReactComponent as D15LineIcon } from '@components/landing/icons/d15-line.svg';
// import { ReactComponent as D16LabelIcon } from '@components/landing/icons/d16-label.svg';
// import { ReactComponent as D16P1Icon } from '@components/landing/icons/d16-1.svg';
// import { ReactComponent as D16P2Icon } from '@components/landing/icons/d16-2.svg';
// import { ReactComponent as D16P3Icon } from '@components/landing/icons/d16-3.svg';
// import { ReactComponent as D16P4Icon } from '@components/landing/icons/d16-4.svg';
// import { ReactComponent as D16P5Icon } from '@components/landing/icons/d16-5.svg';
// import { ReactComponent as D16P6Icon } from '@components/landing/icons/d16-6.svg';
// import { ReactComponent as D16P7Icon } from '@components/landing/icons/d16-7.svg';
// import { ReactComponent as D16P8Icon } from '@components/landing/icons/d16-8.svg';
// import { ReactComponent as D16P9Icon } from '@components/landing/icons/d16-9.svg';
// // import { ReactComponent as D17P1Icon } from '@components/landing/icons/d17-1.svg';
// // import { ReactComponent as D17P2Icon } from '@components/landing/icons/d17-2.svg';
// // import { ReactComponent as D18LabelIcon } from '@components/landing/icons/d18-label.svg';
// // import { ReactComponent as D18P1Icon } from '@components/landing/icons/d18-1.svg';
// // import { ReactComponent as D18P2Icon } from '@components/landing/icons/d18-2.svg';
// // import { ReactComponent as D18P3Icon } from '@components/landing/icons/d18-3.svg';
// // import { ReactComponent as D18P4Icon } from '@components/landing/icons/d18-4.svg';
// import { ReactComponent as D19BgIcon } from '@components/landing/icons/d19-bg.svg';

import './styles.less';
// import '@components/landing/assets/stylesForIcons/d1-img.styles.css';
// import '@components/landing/assets/stylesForIcons/d3-1.styles.css';
// import '@components/landing/assets/stylesForIcons/d3-2.styles.css';
// import '@components/landing/assets/stylesForIcons/d5-1.styles.css';
// import '@components/landing/assets/stylesForIcons/d5-2.styles.css';
// import '@components/landing/assets/stylesForIcons/d5-3.styles.css';
// import '@components/landing/assets/stylesForIcons/d6-1.styles.css';
// import '@components/landing/assets/stylesForIcons/d6-2.styles.css';
// import '@components/landing/assets/stylesForIcons/d6-4.styles.css';
// import '@components/landing/assets/stylesForIcons/d7-brain.styles.css';
// import '@components/landing/assets/stylesForIcons/d9-1.styles.css';
// import '@components/landing/assets/stylesForIcons/d9-2.styles.css';
// import '@components/landing/assets/stylesForIcons/d9-3.styles.css';
// import '@components/landing/assets/stylesForIcons/d9-4.styles.css';
// import '@components/landing/assets/stylesForIcons/d13-img.styles.css';
import "@fancyapps/ui/dist/fancybox.css";

class LandingPage extends Component {
  state = {
    receiveMaterialsChecked: true,
    agreement: true,
    // ambassadorsCount: 0,
    // timer: undefined
  }

  // componentDidMount() {
  //   this.getAmbassadors();
  //
  //   const timer = setInterval(() => this.getAmbassadors(), 60 * 1000)
  //
  //   this.setState({ timer })
  // }
  //
  // componentWillUnmount() {
  //   const { timer } = this.state;
  //   timer && clearInterval(timer);
  // }
  //
  // getAmbassadors = async () => {
  //   this.setState({ loading: true });
  //
  //   const {status, data} = await api.getAmbassadors();
  //
  //   if (status === 200 && data > 0) {
  //     this.setState({ ambassadorsCount: data })
  //   }
  //
  //   this.setState({ loading: false })
  // }

  render() {
    const { setFormType } = this.props;
    // const { ambassadorsCount, loading } = this.state;
    // const { receiveMaterialsChecked, agreement } = this.state;

    Fancybox.bind("[data-fancybox]", {
      Toolbar: {
        display: [
          "close",
        ],
      },
      Navigation: false,
    });

    // const owlOptionsD11 = {
    //   items:2,
    //   loop:true,
    //   nav:true,
    //   responsive: {
    //     700:{items:2},
    //     0:{
    //       items:1
    //     }
    //   },
    //   navElement: 'button',
    //   navText: [
    //     `
    //       <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M7.17188 13L1.17188 7L7.17187 0.999999" stroke="url(#paint0_linear_44_2242)"/>
    //         <defs>
    //           <linearGradient id="paint0_linear_44_2242" x1="7.01187" y1="1" x2="0.87207" y2="1.52661" gradientUnits="userSpaceOnUse">
    //             <stop stop-color="#8A25FC"/>
    //             <stop offset="1" stop-color="#ED4AB8"/>
    //           </linearGradient>
    //         </defs>
    //       </svg>
    //     `,
    //     `
    //       <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M1.17188 1L7.17188 7L1.17188 13" stroke="url(#paint0_linear_44_2245)"/>
    //         <defs>
    //           <linearGradient id="paint0_linear_44_2245" x1="1.33188" y1="13" x2="7.47168" y2="12.4734" gradientUnits="userSpaceOnUse">
    //             <stop stop-color="#8A25FC"/>
    //             <stop offset="1" stop-color="#ED4AB8"/>
    //           </linearGradient>
    //         </defs>
    //       </svg>
    //     `,
    //   ],
    // }
    // const owlOptionsD15 = {
    //   items:1,
    //   loop: true,
    //   margin:10,
    //   mouseDrag: false,
    //   nav:true,
    //   navElement: 'button',
    //   navText: [
    //     `
    //       <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M7.17188 13L1.17188 7L7.17187 0.999999" stroke="url(#paint0_linear_44_2242)"/>
    //         <defs>
    //           <linearGradient id="paint0_linear_44_2242" x1="7.01187" y1="1" x2="0.87207" y2="1.52661" gradientUnits="userSpaceOnUse">
    //             <stop stop-color="#8A25FC"/>
    //             <stop offset="1" stop-color="#ED4AB8"/>
    //           </linearGradient>
    //         </defs>
    //       </svg>
    //     `,
    //     `
    //       <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M1.17188 1L7.17188 7L1.17188 13" stroke="url(#paint0_linear_44_2245)"/>
    //         <defs>
    //           <linearGradient id="paint0_linear_44_2245" x1="1.33188" y1="13" x2="7.47168" y2="12.4734" gradientUnits="userSpaceOnUse">
    //             <stop stop-color="#8A25FC"/>
    //             <stop offset="1" stop-color="#ED4AB8"/>
    //           </linearGradient>
    //         </defs>
    //       </svg>
    //     `,
    //   ]
    // }

    return (
      <div className="home">

        <div className="d1">
          <img src="/landing/images/d1.png" alt="" className="d1-bg md-hide"/>
          <img src="/landing/images/d1t.png" alt="" className="d1-bg md-show xs-hide"/>
          <img src="/landing/images/d1m.png" alt="" className="d1-bg xs-show"/>
          <div className="wrap">
            <div className="d1-left">
              <div className="d1-ttl">
                <strong>DAO SoulMate Ecosystem</strong><br/>
                &nbsp;&mdash;&nbsp;ваш путь к&nbsp;технологическому <br/>
                совершенству и&nbsp;личностному росту <br/>
                {/*<br className="md-show" /> и&nbsp;новичков <br/>*/}
              </div>
              <div className="d1-btns flex justify-start mr-8 mt-8">
                <button
                  className="header-btn gta !mr-5 !opacity-100"
                  onClick={() => setFormType(FORM_REGISTRATION)}
                >
                  Регистрация
                </button>
                <button
                  className="header-btn gta !mr-5 !opacity-100"
                  onClick={() => setFormType(FORM_LOGIN)}
                >
                  Вход
                </button>
              </div>
              <div className="d1-text">
                <strong>DAO SoulMate Ecosystem</strong>
                &nbsp;&mdash;&nbsp;это технологичное сообщество, <br className="xs-hide" />объединяющее людей для разностороннего развития. <br className="xs-hide" />
                Здесь используется цифровая архитектура в&nbsp;виде <br className="md-show" />различных <br className="md-hide" />подсистем, которые направлены на <br className="md-show" />реализацию эволюционной <br className="md-hide" />цели.
                Мы активно <br className="md-show" />занимаемся разработкой и внедрением <br className="md-show" />технологий, <br className="md-hide" />непосредственно способствующих <br className="md-show" />благополучию общества.
                <br className="md-show" />
                {/*Получите все необходимые знания, навыки и&nbsp;инструменты <br/>*/}
                {/*для <br className="md-show" /> заработка и&nbsp;развития на&nbsp;крипто рынке*/}
              </div>
              {/*<div className="d1-btns flex justify-start mr-8 mt-14 ambassadors md-hide">
                <div className="landing-standart-btn landing-standart-btn-border ">
                  <div className="landing-standart-btn-text flex flex-row items-center gap-2">
                    <div>Количество Амбассадоров компании:</div>
                    <div className="text-xl ml-4 min-w-[32px] min-h-[32px] text-center leading-8">
                      <Spin spinning={loading}>
                        {ambassadorsCount}
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>*/}
            </div>
            {/*<D1ImgIcon className="d1-img "/>*/}
            <img src="/landing/svg/d1-img.svg" alt="" className="d1-img md-hide" />
            <img src="/landing/svg/d1-img.svg" alt="" className="d1-img md-show" />
          </div>
        </div>

        {/*<div className="d1-btns flex justify-start mt-0 lg:mt-[100px] mb-10 ambassadors md-show">
          <div className="wrap">
            <div className="landing-standart-btn landing-standart-btn-border !rounded-sm">
              <div className="landing-standart-btn-text flex flex-row items-center justify-between !rounded-lg !px-4">
                <div className="text-base">Количество Амбассадоров компании:</div>
                <div className="text-xl !p-4 min-w-[60px] min-h-[60px] text-center">
                  <Spin spinning={loading}>
                    {ambassadorsCount}
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>*/}

        {/*<div className="d2 lg:!mt-0">
          <div className="wrap">
            <div className="d2-body">
              <div className="d2-left">
                <div className="d2-ttl">
                  Зарегистрируйтесь на&nbsp;нашей <br/>
                  платформе
                  <strong> и&nbsp;получите:</strong>
                </div>
                <div className="landing-standart-btn gta md-hide" data-anchor=".d10">
                  <button
                    className="landing-standart-btn-text"
                    onClick={() => setFormType(FORM_REGISTRATION)}
                  >
                    Зарегистрироваться
                  </button>
                  <div className="landing-standart-btn-shadow"/>
                </div>
              </div>
              <div className="d2-right">
                <div className="d2-item">
                  <img src="/landing/svg/d2-1.svg" alt="" />
                  <strong>Доступ в&nbsp;закрытый канал</strong>
                  <p>с инвесторами и&nbsp;экспертами</p>
                </div>
                <div className="d2-item">
                  <img src="/landing/svg/d2-2.svg" alt="" />
                  <strong>Архив полезных материалов</strong>
                  <p>от основателей SoulMate</p>
                </div>
              </div>
              <div className="landing-standart-btn gta md-show mt-10" data-anchor=".d10">
                <button
                  className="landing-standart-btn-text"
                  onClick={() => setFormType(FORM_REGISTRATION)}
                >
                  Зарегистрироваться
                </button>
                <div className="landing-standart-btn-shadow"/>
              </div>
            </div>
          </div>
        </div>*/}

        {/*<div className="d3">
          <div className="wrap !justify-center">
            <div className="d3-item">
              <img src="/landing/svg/d3-1.svg" alt="" className="d3-img" />
              <div className="d3-body">
                <div className="d3-ttl">
                  <strong>Закрытое комьюнити</strong>
                  <br/>
                  с&nbsp;новостями, перспективными <br/>
                  проектами, анонсами новых программ <br/>
                  и&nbsp;советами от наших основателей
                </div>
                <div className="d3-text">
                  Вступайте в&nbsp;закрытый Telegram - <br/>
                  канал для “своих”
                </div>
                <button
                  className="landing-standart-btn landing-standart-btn-border"
                  onClick={() => openInNewTab(TELEGRAM_FOR_MAIN)}
                >
                  <div className="landing-standart-btn-text">
                    Вступить<br className='xs-show' /> в&nbsp;Telegram-канал
                    <TelegramIcon className='inline' />
                  </div>
                </button>
              </div>
            </div>
            { / * <div className="d3-item">
              <img src="/landing/svg/d3-2.svg" alt="" className="d3-img" />
              <div className="d3-body">
                <div className="d3-ttl">
                  <strong>Презентация о компании</strong>
                  <br/>
                  с&nbsp;описанием всех возможностей <br/>
                  сообщества и&nbsp;бизнес-академии <br/>
                  для партнеров
                </div>
                <div className="d3-text">
                  Получите презентацию в&nbsp;Telegram <br/>
                  в&nbsp;течение минуты
                </div>
                <button
                  className="landing-standart-btn landing-standart-btn-border"
                  onClick={() => openInNewTab('https://t.me/You_Soul_Mate_Bot')}
                >
                  <div className="landing-standart-btn-text">
                    Получить презентацию<br className='xs-show' /> о компании
                    <TelegramIcon className='inline' />
                  </div>
                </button>
              </div>
            </div> * / }
          </div>
        </div>*/}

        {/*<div className="d4">
          <div className="wrap">
            <div className="d4-left">
              <h2 className="standart-ttl">
                Образовательная <br/>
                деятельность полностью <br/>
                сертифицирована
              </h2>
              <p className="standart-text">
                Лицензия дает нам право, выдавать <br/>
                сертификаты участникам академии
              </p>
            </div>
            <div className="d4-img">
              <img src={require('../assets/images/d4-img.png')} alt=""/>
              <a href="static/imgs/d4-img.png" data-fancybox className="d4-zoom">
                <div>
                  <div>
                    <SearchIcon />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>*/}

        {/*<div className="d4">
          <div className="wrap wrap2">
            <h2 className="standart-ttl">
              <strong>Дополнительные общеобразовательные программы</strong>
            </h2>
            <p className="standart-text">
              По ссылкам ниже вы можете скачать и ознакомиться с программами обучения
            </p>
            <div className="d4-img d3-body">
              <button
                className="landing-standart-btn landing-standart-btn-border"
                onClick={() => openInNewTab('/files/Program__Cryptocurrency_Market_Consultant.pdf')}
              >
                <div className="landing-standart-btn-text">
                  Консультант по рынку криптовалют
                </div>
              </button>
              <button
                className="landing-standart-btn landing-standart-btn-border"
                onClick={() => openInNewTab('/files/Program__Crypto-Investor.pdf')}
              >
                <div className="landing-standart-btn-text">
                  Крипто-инвестор
                </div>
              </button>
            </div>
          </div>
        </div>*/}

        <div className="d5">
          <div className="wrap">
            <div className="standart-ttl">
              <strong>Эволюционная цель DAO SoulMate Ecosystem</strong>
            </div>
            <div className="d5-flex">
              <div className="d5-item">
                <div className="d5-img">
                  <img src="/landing/svg/d5-1.svg" alt="" width="260" className="" style={{ maxWidth: '1000px' }} />
                </div>
                <p>
                  Создание глобальной децентрализованной, инфраструктурной
                  экосистемы, объединяющей людей в сообщество и
                  предоставляющей доступ к инновационным WEB3 сервисам с
                  целью сделать жизнь каждого из нас ещё удобней и
                  лучше
                </p>
              </div>
              <div className="d5-item">
                <div className="d5-img">
                  <img src="/landing/svg/d5-2.svg" alt="" width="260" className="" />
                </div>
                <p>
                  Мы стремимся создать благоприятное информационное
                  пространство, способствующее СОтворчеству, а также
                  целостному развитию нашего сообщества.
                </p>
              </div>
              <div className="d5-item">
                <div className="d5-img">
                  <img src="/landing/svg/d5-3.svg" alt="" width="260" className="" />
                </div>
                <p>
                  Видение нашей команды прогрессивно и нацелено на служение и заботу о сообществе.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="d6">
          <div className="wrap">
            <div className="standart-ttl">
              <strong>SoulMate — это целый мир </strong>
              <br/>
              для крипто-инвестора
            </div>
            <div className="d6-flex">
              <div className="d6-item">
                <img src="/landing/svg/d6-1.svg" alt="" className="d6-img xs-hide" />
                <img src="/landing/svg/d6-1.svg" alt="" className="d6-img xs-show d6-xs-bt0 d6-img-n" />
                <div className="d6-ttl">
                  Актуальная база знаний, <br/>
                  изменений и&nbsp;новостей
                </div>
              </div>
              <div className="d6-item">
                <img src="/landing/svg/d6-2.svg" alt="" className="d6-img xs-hide d6-hat" />
                <img src="/landing/svg/d6-2.svg" alt="" className="d6-img xs-show" />
                <div className="d6-ttl">
                  Крипто-академия <br/>
                  с&nbsp;4 факультетами
                </div>
              </div>
              <div className="d6-item">
                <img src="/landing/svg/d13-img.svg" alt="" className="d6-img xs-hide" />
                <img src="/landing/svg/d13-img.svg" alt="" className="d6-img xs-show" />
                <div className="d6-ttl">
                  Бизнес-академия <br/>
                  для партнеров
                </div>
              </div>
              <div className="d6-item">
                <img src="/landing/svg/d6-4.svg" alt="" className="d6-img xs-hide d6-tel" />
                <img src="/landing/svg/d6-4.svg" alt="" className="d6-img xs-show" />
                <div className="d6-ttl">
                  Закрытое комьюнити <br/>
                  для «своих» с&nbsp;реальными <br/>
                  инвесторами
                </div>
              </div>
            </div>
          </div>
        </div>*/}

        {/*<div className="d7">
          <div className="wrap">
            <div className="standart-ttl">
              <strong>Держатель NFT Ambassador имеет:</strong>
            </div>
            <div className="d7-flex">
              <div className="d7-item">
                <strong>Цифровой договор с SME</strong>
              </div>
              <div className="d7-item">
                <strong>Статус резидента закрытого - the SoulMate Ambassador Club</strong>
              </div>
              <div className="d7-item">
                <strong>Доступ к «Амбассадорской программе»</strong>
              </div>
              <div className="d7-item">
                <strong>Ежеквартальные выплаты дивидендов DAO</strong>
              </div>
              <div className="d7-item">
                <strong>Участие в Airdrop, раздача токенов SOUL (White List 1,2 и 3)</strong>
              </div>
              <div className="d7-item">
                <strong>Силу голоса и влияние на развитие DAO</strong>
              </div>
              <div className="d7-item">
                <strong>Развитие в рамках Корпоративного Университета</strong>
              </div>
              <img src="/landing/images/landing_man_0591.png" alt="" className="d7-img d7-img-man" />
            </div>
          </div>
        </div>*/}

        {/* <div className="d8">
          <div className="wrap">
            <div className="d8-body">
              <img src={require('../assets/images/d8-bg.png')}  className="md-hide" alt=""/>
              <img src={require('../assets/images/d8t-bg.png')}  alt="" className="md-show"/>
              <div className="d8-head">
                <div className="d8-left">
                  <strong>Получить демо-доступ </strong><br/>
                  &nbsp;к некоторым материалам <br/>
                  базы знаний SoulMate
                </div>
                <div className="d8-right">
                  <form action="@components/landing/page/index" disabled={!receiveMaterialsChecked}>
                    <div className="d8-form">
                      <div className="d8-user">
                        <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.55">
                            <path
                              d="M7.17749 1.21444C6.70594 0.689166 6.04731 0.399902 5.32034 0.399902C4.5895 0.399902 3.92869 0.687416 3.45931 1.20944C2.98484 1.73722 2.75367 2.4545 2.80795 3.22904C2.91554 4.75711 4.04258 6.00018 5.32034 6.00018C6.5981 6.00018 7.7232 4.75736 7.83249 3.22954C7.8875 2.462 7.65487 1.74622 7.17749 1.21444Z"
                              fill="#333333"/>
                            <path
                              d="M9.82189 11.5998H0.818625C0.700781 11.6013 0.584076 11.5773 0.477003 11.5295C0.36993 11.4818 0.275182 11.4115 0.199651 11.3238C0.033397 11.1312 -0.033616 10.8681 0.0160043 10.6021C0.231878 9.44133 0.905589 8.46624 1.9645 7.78172C2.90524 7.17405 4.09689 6.8396 5.32026 6.8396C6.54363 6.8396 7.73528 7.1743 8.67602 7.78172C9.73493 8.46599 10.4086 9.44108 10.6245 10.6019C10.6741 10.8679 10.6071 11.1309 10.4409 11.3236C10.3654 11.4113 10.2706 11.4816 10.1635 11.5294C10.0565 11.5772 9.93976 11.6012 9.82189 11.5998Z"
                              fill="#333333"/>
                          </g>
                        </svg>
                      </div>
                      <input type="text" placeholder="Введите ваш E-mail" disabled={!receiveMaterialsChecked} readOnly={!receiveMaterialsChecked}/>
                      <button className="landing-standart-btn" disabled={!receiveMaterialsChecked}>
                        <span className={classNames("landing-standart-btn-text", !receiveMaterialsChecked ? 'disabled' : '')}>
                          Получить материалы из базы
                        </span>
                        <span className="landing-standart-btn-shadow"/>
                      </button>
                    </div>
                    <label className="d8-check">
                      <input
                        type="checkbox"
                        checked={receiveMaterialsChecked}
                        onChange={() => this.setState({receiveMaterialsChecked: !receiveMaterialsChecked})}
                      />
                      <div>
                        <D8CheckIcon />
                      </div>
                      <p>
                        Нажимая кнопку «Получить материалы из базы», я даю <br/>
                        согласие на&nbsp;обработку моих персональных данных
                      </p>
                    </label>
                  </form>
                </div>
              </div>
              <div className="d8-foot">
                <div className="d8-item">
                  <D8P1Icon />
                  <strong>Как зарабатывать на&nbsp;крипте</strong>
                  <p>Играя в&nbsp;игры в&nbsp;2022 году</p>
                </div>
                <div className="d8-item">
                  <D8P2Icon />
                  <strong>Куда инвестировать</strong>
                  <p>на крипто - рынке сегодня</p>
                </div>
                <div className="d8-item">
                  <D8P3Icon />
                  <strong>Как найти проект</strong>
                  <p>
                    в&nbsp;котором можно получить <br/>
                    1 000% прибыли
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>*/}

        {/*<div className="d9">
          <div className="wrap">
            <div className="standart-ttl">
              Крипто-академия
            </div>
            <div className="standart-label !font-semibold">
              4 факультета, охватывающие <br className="md-show" /> все сегменты рынка
            </div>
            <div className="standart-text">
              Вы сможете узнать все тонкости работы на&nbsp;крипто-рынке, <br/>
              получить работающие стратегии и&nbsp;уверенно <br/>
              зарабатывать на&nbsp;инвестициях
            </div>
            <div className="d9-flex">
              <div className="d9-col">
                <div className="d9-item">
                  <img src="/landing/svg/d9-1.svg" alt="" width="250" className="d9-img" />
                  <strong>DeFi</strong>
                  <p>
                    Все виды финансовых инструментов <br/>
                    в&nbsp;виде сервисов и&nbsp;приложений, <br/>
                    созданных на&nbsp;блокчейне
                  </p>
                </div>
              </div>
              <div className="d9-col">
                <div className="d9-item">
                  <img src="/landing/svg/d9-2.svg" alt="" className="d9-img d9-img-new" />
                  <strong>Крипто-трейдинг</strong>
                  <p>
                    Эффективные и&nbsp;безопасные <br/>
                    инвестиции цифровыми валютами
                  </p>
                </div>
              </div>
              <div className="d9-col">
                <div className="d9-item">
                  <img src="/landing/images/d9-3.png" alt="" width="180" className="d9-img"/>
                  <img src="/landing/svg/d9-3.svg" alt="" width="180" className="d9-img" />
                  <strong>
                    GameFi и&nbsp;<br/>
                    Metaverse
                  </strong>
                  <p>
                    Блокчейн-проекты, сочетающие <br/>
                    в&nbsp;себе игровые механики, NFT и&nbsp;DeFi <br/>
                    и&nbsp;позволяют монетизировать игровой опыт
                  </p>
                </div>
              </div>
              <div className="d9-col">
                <div className="d9-item">
                  <img src="/landing/svg/d9-4.svg" alt="" className="d9-img" width="260" />
                  <strong>
                    ICO, IDO, <br/>
                    Presale
                  </strong>
                  <p>
                    Все способы инвестирования <br/>
                    в&nbsp;проекты на&nbsp;разных стадиях <br/>
                    на&nbsp;базе технологии блокчейн
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>*/}

        {/*<div className="d10">
          <div className="wrap">
            <div className="standart-ttl">
              <strong>Зарегистрируйтесь на&nbsp;платформе,</strong>
              &nbsp;чтобы подать <br/>
              заявку на&nbsp;обучение в&nbsp;крипто-академии
            </div>
            <div className="d8-right text-center">
              { / * <form action="@components/landing/page/index">
                <div className="d8-form">
                  <div className="d8-user">
                    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.55">
                        <path
                          d="M7.17749 1.21444C6.70594 0.689166 6.04731 0.399902 5.32034 0.399902C4.5895 0.399902 3.92869 0.687416 3.45931 1.20944C2.98484 1.73722 2.75367 2.4545 2.80795 3.22904C2.91554 4.75711 4.04258 6.00018 5.32034 6.00018C6.5981 6.00018 7.7232 4.75736 7.83249 3.22954C7.8875 2.462 7.65487 1.74622 7.17749 1.21444Z"
                          fill="#333333"/>
                        <path
                          d="M9.82189 11.5998H0.818625C0.700781 11.6013 0.584076 11.5773 0.477003 11.5295C0.36993 11.4818 0.275182 11.4115 0.199651 11.3238C0.033397 11.1312 -0.033616 10.8681 0.0160043 10.6021C0.231878 9.44133 0.905589 8.46624 1.9645 7.78172C2.90524 7.17405 4.09689 6.8396 5.32026 6.8396C6.54363 6.8396 7.73528 7.1743 8.67602 7.78172C9.73493 8.46599 10.4086 9.44108 10.6245 10.6019C10.6741 10.8679 10.6071 11.1309 10.4409 11.3236C10.3654 11.4113 10.2706 11.4816 10.1635 11.5294C10.0565 11.5772 9.93976 11.6012 9.82189 11.5998Z"
                          fill="#333333"/>
                      </g>
                    </svg>
                  </div>
                  <input type="text" placeholder="Введите ваш E-mail"/>
                  <button
                   className="landing-standart-btn"
                    disabled={!agreement}
                    onClick={() => setFormType(FORM_REGISTRATION)}
                  >
                    <span className={classNames("landing-standart-btn-text", !agreement ? 'disabled' : '')}>
                      Зарегистрироваться
                    </span>
                    <span className="landing-standart-btn-shadow" />
                  </button>
                </div>
                <label className="d8-check">
                  <input
                    type="checkbox"
                    checked={agreement}
                    onChange={() => this.setState({agreement: !agreement})}
                  />
                  <div>
                    <D8CheckIcon />
                  </div>
                  <p>
                    Нажимая кнопку «Зарегистрироваться», я даю <br/>
                    согласие на&nbsp;обработку моих персональных данных
                  </p>
                </label>
              </form> * / }
              <button
                className="landing-standart-btn w-"
                onClick={() => setFormType(FORM_REGISTRATION)}
              >
                <div className="landing-standart-btn-text">
                  Зарегистрироваться
                </div>
                <div className="landing-standart-btn-shadow" />
              </button>
            </div>
          </div>
        </div>*/}

        {/*<div className="d11">
          <img src="/landing/svg/d11.svg" alt="" className="d11-bg md-hide" />
          <img src="/landing/svg/d11t-bg.svg" alt="" className="d11-bg md-show" />
          <div className="wrap">
            <img src="/landing/svg/d11-line.svg" alt="" className="d11-line" />
            <div className="d11-left">
              <div className="standart-ttl">
                <strong>Бизнес-академия</strong>
                <br/>
                для партнеров
              </div>
              <div className="standart-text">
                <span>Вы сможете найти <br className="md-show" /> новые источники дохода</span>
                <br className="md-hide" />
                и&nbsp;создать свой международный бизнес, <br/>
                построенный на&nbsp;технологии блокчейн
              </div>
            </div>
            <div className="d11-right">
              <div className="d11-animate">
                <img src="/landing/images/d11.png" alt="" className="d11-img"/>
              </div>
              <div className="standart-ttl">
                Вы получите возможность <br/>
                получать доход с&nbsp;помощью <br/>
                <strong>4 методов</strong>
              </div>
              <OwlCarousel options={owlOptionsD11} className="d11-slider owl-carousel">
                <div className="d11-item" key="Доход">
                  <div className="d11-icon">
                    <img src="/landing/svg/d11-1.svg" alt="" />
                  </div>
                  <p>
                    <strong>Доход </strong>
                    от личных и&nbsp;структурных <br/>
                    продаж пакетов
                  </p>
                </div>
                <div className="d11-item" key="Пассивный Доход">
                  <div className="d11-icon">
                    <img src="/landing/svg/d11-2.svg" alt="" />
                  </div>
                  <p>
                    <strong>Пассивный доход</strong>
                    от абонентской оплаты
                  </p>
                </div>
                <div className="d11-item" key="Дивидендный Доход">
                  <div className="d11-icon">
                    <img src="/landing/svg/d11-1.svg" alt="" />
                  </div>
                  <p>
                    <strong>Дивидендный доход</strong>
                    часть от чистой прибыли <br/>
                    компании твоей структуры
                  </p>
                </div>
                <div className="d11-item" key="Совладельческий Доход">
                  <div className="d11-icon">
                    <img src="/landing/svg/d11-2.svg" alt="" />
                  </div>
                  <p>
                    <strong>Совладельческий доход</strong>
                    часть от всей чистой <br/>
                    прибыли компании
                  </p>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>*/}

        <div className="d12">
          <div className="wrap">
            <img src="/landing/images/d1.png" alt="" className="d12-bg-new md-hide" />
            <div className="d12-body">
              <div className="d12-content">
                <div className="standart-ttl">
                  <strong>The SoulMate Ambassador's Club</strong>
                </div>
                <p>
                  &mdash;&nbsp;Закрытый клуб, который прежде всего является управленческим
                  аппаратом, «сердцем» DAO SoulMate Ecosystem. Резиденты <br className="md-show" />клуба,
                  Амбассадоры, занимают ключевую позицию во всей экосистеме
                  SoulMate, являются ее акционерами, а также <br className="md-show" />имеют доступ к
                  множеству привилегий.
                </p>
                <p>
                  <strong>Задачи, которые решает <br className="md-show" />The SoulMate Ambassador's Club</strong>
                </p>
                <div className="d12-icon">
                  <ApartmentOutlined />
                  <p>
                    1. Масштабирование экосистемы SoulMate, благодаря <br className="md-show" />поддержке
                    зарегистрированных пользователей <br className="md-show" />и активному привлечению новых;
                  </p>
                </div>
                <br />
                <div className="d12-icon">
                  <CheckSquareOutlined />
                  <p>
                    2. Участие в голосованиях DAO. Амбассадоры, <br className="md-show" />принимают
                    ключевые решения о&nbsp;развитии <br className="md-show" />экосистемы SoulMate, воплощая эволюционную <br className="md-show" />миссию проекта.
                  </p>
                </div>
              </div>
              <img src="/landing/svg/d1-img.svg" alt="" className="d12-img-n xs-show" />
              <img src="/landing/svg/d1-img.svg" alt="" className="d12-img-n md-hide" />
              <img src="/landing/images/d12t.png" alt="" className="d12-bg md-show xs-hide"/>
              <img src="/landing/images/d12m.png" alt="" className="d12-bg xs-show"/>
            </div>
          </div>
        </div>

        <div className="d7 d7-new">
          <div className="wrap">
            <div className="standart-ttl">
              <strong>Держатель NFT Ambassador имеет:</strong>
            </div>
            <div className="d7-flex">
              <div className="d7-item">
                <div className="d7-item-icon"><img src="/landing/svg/d7-item-icon1.svg" alt="" /></div>
                <strong>Цифровой договор <span>с SME</span></strong>
              </div>
              <div className="d7-item">
                <div className="d7-item-icon"><img src="/landing/svg/d7-item-icon2.svg" alt="" /></div>
                <strong><span>Возможность получения</span> ежеквартальных дивидендных <span>вознаграждений *</span></strong>
              </div>
              <div className="d7-item">
                <div className="d7-item-icon"><img src="/landing/svg/d7-item-icon3.svg" alt="" /></div>
                <strong>Доступ <span>к «Амбассадорской программе»</span></strong>
              </div>
              <div className="d7-item">
                <div className="d7-item-icon"><img src="/landing/svg/d7-item-icon4.svg" alt="" /></div>
                <strong>Силу голоса и влияние <span>на развитие DAO</span></strong>
              </div>
              <div className="d7-item">
                <div className="d7-item-icon"><img src="/landing/svg/d7-item-icon5.svg" alt="" /></div>
                <strong>Участие в Airdrop, <br />Раздача токенов SOUL <span>(White List 1,2 и 3)</span></strong>
              </div>
              <div className="d7-item">
                <div className="d7-item-icon"><img src="/landing/svg/d7-item-icon6.svg" alt="" /></div>
                <strong>Статус резидента <br />закрытого <span>The SoulMate Ambassador's Club</span></strong>
              </div>
              <div className="d7-item">
                <div className="d7-item-icon"><img src="/landing/svg/d7-item-icon7.svg" alt="" /></div>
                <strong>Развитие <span>в рамках Корпоративного Университета</span></strong>
              </div>
              <img src="/landing/images/man-image.png" alt="" className="d7-img d7-img-man" />
            </div>
            <div className="d7-text text-center">
              * Условия начисления дивидендных вознаграждений описаны в&nbsp;
              <button className="underline" onClick={() => openInNewTab(SOULMATE_ECOSYSTEM)}>
                White Paper DAO SoulMate Ecosystem
              </button>
            </div>
          </div>
        </div>

        <div className="d13">
          <div className="wrap">
            <div className="standart-ttl">
              <strong>Ключевые характеристики<br />DAO SoulMate Ecosystem</strong>
            </div>
            <div className="d13-flex">
              <div className="d13-item">
                <strong>Децентрализация</strong><br />
                Основа SoulMate - децентрализация, что означает отсутствие
                единого контроля и управления со стороны центральных
                органов или институтов. Вместо этого, решения принимаются
                с помощью голосований сообщества или автоматически
                исполняющимися смарт-контрактами.
              </div>
              <div className="d13-item">
                <strong>Открытость</strong><br />
                Экосистема открыта для всех пользователей. Все транзакции
                и данные в блокчейне являются публичными и доступными
                для проверки, что обеспечивает высокую прозрачность и
                подотчетность.
              </div>
              <div className="d13-item">
                <strong>Личная ответственность</strong><br />
                Экосистема SoulMate - это организм, сердцем которого
                являются люди, все до единого пользователи. В тот момент,
                когда абсолютно каждый пользователь стремиться не только
                взять, но и привнести свой вклад в экосистему, устремлен к
                реализации эволюционной цели компании, тогда, достигается
                полная автономность, а значит устойчивость экосистемы и
                успех всех нас.
              </div>
              <img src="/landing/svg/d13-img.svg" width="421" height="456" alt="" className="d13-img" />
            </div>
            {/*<img src="/landing/svg/d13-line.svg" alt="" className="d13-line" />*/}
          </div>
        </div>

        {/*<div className="d14">
          <div className="wrap">
            <div className="d14-left">
              <div className="standart-label">
                <img src="/landing/svg/d14-label.svg" alt="" />
                Об основателях
              </div>
              <div className="standart-ttl">
                Основатели SoulMate
              </div>
              <div className="standart-text">
                Эксперты крипто-рынка с&nbsp;опытом работы <br/>
                более 7 лет и&nbsp;многомиллионными портфелями
              </div>
            </div>
            <div className="d14-items">
              <div className="d14-item">
                <img src="/landing/images/d14-1.png" alt="" className="d14-img"/>
                <div className="d14-content">
                  <strong>Виталий Пятенко</strong>
                  <p>
                    Опыт в&nbsp;МЛМ бизнесе с&nbsp;2012 <br/>
                    На крипторынке с&nbsp;2017
                  </p>
                </div>
              </div>
              <div className="d14-item">
                <img src="/landing/images/d14-2.png" alt="" className="d14-img"/>
                <div className="d14-content">
                  <strong>Дмитрий Петров</strong>
                  <p>
                    Опыт в&nbsp;МЛМ бизнесе с&nbsp;2017 <br/>
                    На крипторынке с&nbsp;2017
                  </p>
                </div>
              </div>
              <div className="d14-item">
                <img src="/landing/images/d14-3.png" alt="" className="d14-img"/>
                <div className="d14-content">
                  <strong>Максим Бумарсков</strong>
                  <p>
                    Опыт в&nbsp;МЛМ с&nbsp;2011 <br/>
                    На крипторынке с&nbsp;2014
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>*/}

        {/*<div className="d15">
          <div className="wrap">
            <div className="d15-slider">
              <OwlCarousel options={owlOptionsD15} className="owl-carousel">
                <div className="d15-item" key="Костюков Антон">
                  <div className="d15-img">
                    <img src="/landing/svg/d15-bg.svg" alt="" />
                    <img src="/landing/images/d15-1.png" alt=""/>
                  </div>
                  <div className="d15-content">
                    <div className="d15-ttl">Костюков Антон</div>
                    <div className="d15-text">
                      Эксперт в&nbsp;аналитике в&nbsp;сфере Defi <br/>
                      и&nbsp;аналитике проектов
                    </div>
                    <div className="d15-num">8+ лет</div>
                    <div className="d15-exp">опыта</div>
                  </div>
                </div>
                <div className="d15-item" key="Александр Сальников">
                  <div className="d15-img">
                    <img src="/landing/svg/d15-bg.svg" alt="" />
                    <img src="/landing/images/d15-2.png" alt=""/>
                  </div>
                  <div className="d15-content">
                    <div className="d15-ttl">Александр Сальников</div>
                    <div className="d15-text">Эксперт в&nbsp;DeFi - сектора</div>
                    <div className="d15-num">7+ лет</div>
                    <div className="d15-exp">опыта</div>
                  </div>
                </div>
                <div className="d15-item" key="Бутенко Павел">
                  <div className="d15-img">
                    <img src="/landing/svg/d15-bg.svg" alt="" />
                    <img src="/landing/images/d15-3.png" alt=""/>
                  </div>
                  <div className="d15-content">
                    <div className="d15-ttl">Бутенко Павел</div>
                    <div className="d15-text">
                      Эксперт в&nbsp;трейдинге, ICO и&nbsp;IDO, <br/>&nbsp;а&nbsp;также в&nbsp;аналитике проектов
                    </div>
                    <div className="d15-num">6+ лет</div>
                    <div className="d15-exp">опыта</div>
                  </div>
                </div>
                <div className="d15-item" key="Гринев Александр">
                  <div className="d15-img">
                    <img src="/landing/svg/d15-bg.svg" alt="" />
                    <img src="/landing/images/d15-4.png" alt=""/>
                  </div>
                  <div className="d15-content">
                    <div className="d15-ttl">Гринев Александр</div>
                    <div className="d15-text">Эксперт GameFi</div>
                    <div className="d15-num">5 лет</div>
                    <div className="d15-exp">опыта</div>
                  </div>
                </div>
                <div className="d15-item" key="Авдалян Хачатур">
                  <div className="d15-img">
                    <img src="/landing/svg/d15-bg.svg" alt="" />
                    <img src="/landing/images/d15-5.png" alt=""/>
                  </div>
                  <div className="d15-content">
                    <div className="d15-ttl">Авдалян Хачатур</div>
                    <div className="d15-text">Эксперт в&nbsp;ICO и&nbsp;IDO</div>
                    <div className="d15-num">4+ лет</div>
                    <div className="d15-exp">опыта</div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
            <div className="d15-right">
              <div className="standart-label">
                <img src="/landing/svg/d15-label.svg" alt="" />
                Про экспертов
              </div>
              <div className="standart-ttl">
                Эксперты и&nbsp;спикеры SoulMate
              </div>
              <div className="standart-text">
                Действующие инвесторы с&nbsp;опытом в&nbsp;крипто-<br/>
                рынке более 5 лет и&nbsp;доходом от 10 000$
              </div>
            </div>
            <img src="/landing/svg/d15-line.svg" alt="" className="d15-line" />
          </div>
        </div>*/}

        {/*<div className="d16">
          <div className="wrap">
            <div className="standart-label">
              <img src="/landing/svg/d16-label.svg" alt="" />
              Наши ценности
            </div>
            <div className="standart-ttl">
              Ценности SoulMate
            </div>
            <div className="d8-foot">
              <div className="d8-item">
                <D16P1Icon />
                <img src="/landing/svg/d16-1.svg" alt="" />
                <strong>Ответственность</strong>
                <p>
                  Мое счастье начинается <br/>
                  с&nbsp;меня. Моя жизнь, мои <br/>
                  правила, мой результат
                </p>
              </div>
              <div className="d8-item">
                <img src="/landing/svg/d16-2.svg" alt="" />
                <strong>Проявленность</strong>
                <p>
                  Изучаю, пробую, создаю, <br/>
                  проявляю свои таланты. Каждый <br/>
                  способен к личной реализации
                </p>
              </div>
              <div className="d8-item">
                <img src="/landing/svg/d16-3.svg" alt="" />
                <strong>Честность</strong>
                <p>
                  Правдивость, принципиальность, <br/>
                  верность принятым обязательствам <br/>
                  искренность перед другим <br/>
                  и&nbsp;самим собой
                </p>
              </div>

              <div className="d8-item">
                <img src="/landing/svg/d16-4.svg" alt="" />
                <strong>Щедрость
                </strong>
                <p>
                  Мы за новую финансовую этику: <br/>
                  от жадности к благодарности
                </p>
              </div>
              <div className="d8-item">
                <img src="/landing/svg/d16-5.svg" alt="" />
                <strong>Изобилие</strong>
                <p>
                  Сами деньги дают мне свободу <br/>
                  реализовать свой потенциал — <br/>&nbsp;а&nbsp;это и&nbsp;есть счастье
                </p>
              </div>
              <div className="d8-item">
                <img src="/landing/svg/d16-6.svg" alt="" />
                <strong> Гармония</strong>
                <p>
                  Уверенность в&nbsp;будущем, <br/>
                  самореализация и&nbsp;поддержка
                </p>
              </div>

              <div className="d8-item">
                <img src="/landing/svg/d16-7.svg" alt="" />
                <strong>Эволюция</strong>
                <p>
                  Мы входим в&nbsp;фазу структурных <br/>
                  изменений мировой экономики <br/>
                  и&nbsp;вместе создаем новый <br/>
                  уклад жизни
                </p>
              </div>
              <div className="d8-item">
                <img src="/landing/svg/d16-8.svg" alt="" />
                <strong>Комьюнити</strong>
                <p>
                  В&nbsp;первую очередь мы создаем <br/>
                  сообщество, в&nbsp;котором делая для всех, <br/>
                  ты делаешь для себя
                </p>
              </div>
              <div className="d8-item">
                <img src="/landing/svg/d16-9.svg" alt="" />
                <strong>Простота</strong>
                <p>
                  Мы передаем знание и&nbsp;опыт <br/>
                  простым языком, чтобы каждый мог <br/>
                  применить это в&nbsp;своей практике <br/>
                  и&nbsp;получить результат
                </p>
              </div>
            </div>
          </div>
        </div>*/}

        {/*<div className="d17">
          <img src={require('../assets/images/d17.png')}  alt="" className="d17-bg md-hide"/>
          <img src={require('../assets/images/d17t.png')}  alt="" className="d17-bg md-show"/>
          <div className="wrap">
            <img src={require('../assets/images/d17m.png')}  alt="" className="d17m-bg xs-show"/>
            <div>
              <div className="standart-ttl">
                Если у вас остались вопросы <br/>
                и&nbsp;вы хотите узнать больше о SoulMate <br/>
                <strong>приходите на&nbsp;вебинар</strong>
              </div>
              <div className="d17-text">
                Участие в&nbsp;вебинаре — <strong> бесплатно</strong>
              </div>
            </div>

            <div className="d17-banner">
              <del>4 900 ₽</del>
              <div className="d17-ttl">
                При регистрации <br/>
                с&nbsp;сайта - бесплатно
              </div>
              <div className="landing-standart-btn gta" data-anchor=".d19">
                <div className="landing-standart-btn-text">Хочу на&nbsp;вебинар</div>
                <div className="landing-standart-btn-shadow"/>
              </div>
              <div className="d17-rezerv">Осталось 12 мест из 140</div>
              <div className="d17-foot">
                <div className="d17-item">
                  <div className="d17-icon">
                    <D17P1Icon />
                  </div>
                  <p>
                    <strong>20/10</strong>
                    Старт
                  </p>
                </div>
                <div className="d17-item">
                  <div className="d17-icon">
                    <D17P2Icon />
                  </div>
                  <p>
                    <strong>19:00</strong>
                    МСК
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>*/}

        {/*<div className="d18">
          <div className="wrap">
            <div className="standart-label">
              <D18LabelIcon />
              Вебинар
            </div>
            <div className="standart-ttl">
              На&nbsp;вебинаре разберём <br/>
              <strong>следующие темы</strong>
            </div>

            <div className="d8-foot">
              <div className="d8-item">
                <D18P1Icon />
                <p>
                  Как строить бизнес <br/>
                  с&nbsp;SoulMate
                </p>
              </div>
              <div className="d8-item">
                <D18P2Icon />
                <p>
                  Все возможности <br/>
                  и&nbsp;направления SoulMate
                </p>
              </div>
              <div className="d8-item">
                <D18P3Icon />
                <p>
                  Направления, факультеты <br/>
                  и&nbsp;программы обучения
                </p>
              </div>
              <div className="d8-item">
                <D18P4Icon />
                <p>
                  Как сегодня начать <br/>
                  зарабатывать на&nbsp;крипте <br/>
                  без вложений
                </p>
              </div>
            </div>
          </div>
        </div>*/}

        {/*<div className="d19">
          <div className="wrap">
            <div className="d19-left">
              <div className="d19-ttl">
                <strong>Станьте частью &nbsp;</strong>
                международного <br/>
                сообщества SoulMate
              </div>
              <div className="d19-text">
                Быть вместе, чтобы быть свободным
              </div>
              <div className="d8-right">
                { / * <form action="@components/landing/page/index">
                  <div className="d8-form">

                    <div className="d8-user">
                      <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.55">
                          <path
                            d="M7.17749 1.21444C6.70594 0.689166 6.04731 0.399902 5.32034 0.399902C4.5895 0.399902 3.92869 0.687416 3.45931 1.20944C2.98484 1.73722 2.75367 2.4545 2.80795 3.22904C2.91554 4.75711 4.04258 6.00018 5.32034 6.00018C6.5981 6.00018 7.7232 4.75736 7.83249 3.22954C7.8875 2.462 7.65487 1.74622 7.17749 1.21444Z"
                            fill="#333333"/>
                          <path
                            d="M9.82189 11.5998H0.818625C0.700781 11.6013 0.584076 11.5773 0.477003 11.5295C0.36993 11.4818 0.275182 11.4115 0.199651 11.3238C0.033397 11.1312 -0.033616 10.8681 0.0160043 10.6021C0.231878 9.44133 0.905589 8.46624 1.9645 7.78172C2.90524 7.17405 4.09689 6.8396 5.32026 6.8396C6.54363 6.8396 7.73528 7.1743 8.67602 7.78172C9.73493 8.46599 10.4086 9.44108 10.6245 10.6019C10.6741 10.8679 10.6071 11.1309 10.4409 11.3236C10.3654 11.4113 10.2706 11.4816 10.1635 11.5294C10.0565 11.5772 9.93976 11.6012 9.82189 11.5998Z"
                            fill="#333333"/>
                        </g>
                      </svg>
                    </div>
                    <input type="text" placeholder="Введите ваш E-mail"/>
                    <button className="landing-standart-btn" disabled={!agreement}>
                      <span className={classNames("landing-standart-btn-text", !agreement ? 'disabled' : '')}>
                        Стать частью сообщества
                      </span>
                      <span className="landing-standart-btn-shadow" />
                    </button>
                  </div>

                  <label className="d8-check">
                    <input
                      type="checkbox"
                      checked={agreement}
                      onChange={() => this.setState({agreement: !agreement})}
                    />
                    <div>
                      <D8CheckIcon />
                    </div>
                    <p>
                      Нажимая кнопку «Стать частью сообщества», я даю <br/>
                      согласие на&nbsp;обработку моих персональных
                      данных
                    </p>
                  </label>

                </form> * / }
                <button
                  className="landing-standart-btn"
                  onClick={() => setFormType(FORM_REGISTRATION)}
                >
                  <div className="landing-standart-btn-text">
                    Стать частью сообщества
                  </div>
                  <div className="landing-standart-btn-shadow" />
                </button>
              </div>
            </div>
          </div>
          <img src="/landing/images/d19.png" alt="" className="d19-img md-hide"/>
          <img src="/landing/images/d19t.png" alt="" className="d19-img md-show"/>
          <img src="/landing/svg/d19-bg.svg" alt="" className="d19-bg" />
        </div>*/}
      </div>
    );
  }
}

  // const mapStateToProps = (state) => ({
// //   appStage: state.appStage,
// //   notificationsCounter: state.notifications.count_new,
// //   notifications: state.notifications.notificationsData,
// //   userInfo: state.user.info,
// });
//
// const mapDispatchToProps = {
//   // setAppStageData: setAppStage,
//   // clearUserInfo: setClearUserExtraInfo(),
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(
//   withTranslation()(
//     withRouter(
//       LandingHeader
//     )
//   )
// );

// export default withTranslation()(
//   withRouter(
//     TopBar
//   )
// );

export default LandingPage;
